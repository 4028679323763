import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { NumericInput } from '../NumericInput';

export interface IDiffPriceInputProps {
  title: string;
  symbolTokenA: string;
  symbolTokenB: string;
  value: string;
  minValue?: number;
  maxValue?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isPercentage?: boolean;
  enabled: boolean;
  setEnabled: (value: boolean) => void;
  onDecrement?: () => void;
  onIncrement?: () => void;
}

export const PriceDiffStepCounter = ({
  title,
  symbolTokenA,
  symbolTokenB,
  value,
  minValue,
  maxValue,
  onChange,
  onBlur,
  isPercentage,
  enabled,
  setEnabled,
  onIncrement,
  onDecrement,
}: IDiffPriceInputProps) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.06)',
    background: '#2A2A32',
  }}>
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      borderRight: '1px solid rgba(255, 255, 255, 0.06)',
      width: '44px',
    }}>
      <Checkbox
        checked={enabled}
        onChange={(e) => setEnabled(e.target.checked)}
      />
    </Box>
    <Box sx={{ flex: 1, alignItems: 'center', padding: 3 }}>
      <Typography variant="secondary13" color="text.secondary">
        {title}
      </Typography>
      <NumericInput
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        isPercentage={isPercentage}
        onChange={onChange}
        onBlur={onBlur}
        disabled={!enabled}
        decimalPlaces={12}
        sx={{ fontSize: '18px', ['>input']: { pb: 0.5, pt: 1 } }}
      />
      <Typography variant="secondary12" color="text.tertiary">
        {symbolTokenA} per {symbolTokenB}
      </Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      p: 3,
    }}>
      <Button
        size="small"
        onClick={onIncrement}
        disabled={!enabled}
        sx={{
          width: '28px',
          minWidth: '28px',
          borderRadius: '6px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
        }}
      >
        <AddRoundedIcon/>
      </Button>
      <Button
        size="small"
        onClick={onDecrement}
        disabled={!enabled}
        sx={{
          width: '28px',
          minWidth: '28px',
          borderRadius: '6px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
        }}
      >
        <RemoveRoundedIcon/>
      </Button>
    </Box>
  </Box>
);
