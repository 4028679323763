import { BigNumber } from 'bignumber.js';

export const convertToSqrtPriceX96 = (
  price: BigNumber,
  decimalsToken0: number | undefined,
  decimalsToken1: number | undefined,
) => {
  if (!price || !decimalsToken0 || !decimalsToken1) {
    return BigNumber(0);
  }
  return BigNumber(
    price
      .multipliedBy(BigNumber(10).pow(decimalsToken1))
      .div(BigNumber(10).pow(decimalsToken0))
      .multipliedBy(BigNumber(2).pow(192))
  ).sqrt().decimalPlaces(0);
};
