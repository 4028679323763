import JSBI from 'jsbi';
import { BigNumber } from 'bignumber.js';
import { TickMath } from '@uniswap/v3-sdk';
import { convertToSqrtPriceX96 } from './convertToSqrtPriceX96';

export const priceToTick = (
  price: BigNumber,
  decimalsToken0: number | undefined,
  decimalsToken1: number | undefined,
) => {
  if (!price || !decimalsToken0 || !decimalsToken1) {
    return 0;
  }
  const sqrtPriceX96 = convertToSqrtPriceX96(price, decimalsToken0, decimalsToken1);
  return TickMath.getTickAtSqrtRatio(
    JSBI.BigInt(sqrtPriceX96.toFixed())
  );
};
